import React from "react"
import styles from "./heroform.module.scss"
import Checkbox from "../../controls/checkbox"
import CustomInput from "../../controls/custom-input"
import Translate from "../../../i18n/Translate"
import isMobilePhone from "validator/es/lib/isMobilePhone"
import heroformOverlayService from "../../../services/heroform-overlay-service"
import heroformService from "../../../services/heroform-service"
import { graphql, useStaticQuery } from "gatsby"
import LoaderComponent from "../shared/loader"
import { ReCaptcha } from "react-recaptcha-google"

function useCaptchaToken() {
    const {site} = useStaticQuery(
      graphql`
      query {
        site {
          siteMetadata {
            captchaToken
          }
        }
      }
    `,
    )
    return site.siteMetadata.captchaToken;
}

class HeroCallbackFormRegistration extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            inProgress: false,
            termsAccepted: true,
            name: "",
            phone: "",
            email: "",
            comment: "",
            validation: null,
            showCaptcha: false,
            captcha: null,
        }
    }

    componentDidMount() {
        if (this.captchaElement) {
            this.captchaElement.reset()
        }
    }

    onAgreementChange = (event) => {
        const termsAccepted = event.target.checked
        this.setState({ termsAccepted })
    }

    validatePhoneNumber = (number) => {
        const isValidPhoneNumber = isMobilePhone(number)
        return (isValidPhoneNumber)
    }

    sendForm = async () => {
        const { name, phone, email, inProgress, captcha } = this.state
        if (inProgress) {
            return
        }

        const comment = this.state.comment || heroformOverlayService.defaultMessage

        if( !this.validatePhoneNumber(phone) ) {
            this.setState({
                validation: {
                    fieldErrorCodeList: {
                        phone: 'required'
                    }
                }
            })
            return
        }

        this.setState({ inProgress: true })
        heroformService.send(name, phone, email, comment, captcha, this.props.lang)
          .then(validation => this.setState({ validation }))
          .then(() => this.setState({ inProgress: false }))
          .then(
            () => {
            if (this.isSuccess) {
                this.setState({ name: "", phone: "", email: "" });
                this.props.onSuccess(this.state.validation["registrationTokenSentToCustomer"]);
            }
            })

          .then(() => this.setState({
              showCaptcha: this.needToShowCaptcha,
              captcha: null,
          }))
    }

    handleFieldChange = (name) => (event) => {
        this.setState({ [name]: event.target.value })
    }

    get isSuccess() {
        const { validation } = this.state
        return validation && validation.success
    }

    get needToShowCaptcha() {
        const { validation } = this.state
        return validation && validation.fieldErrorCodeList && validation.fieldErrorCodeList.captcha
    }

    // Генерирует уникальный elementId для ReCaptcha, чтобы могло рендериться несколько элементов
    generateKey = (pre) => {
        return `${pre}_${new Date().getTime()}`
    }

    onLoadRecaptcha() {
        if (this.captchaElement) {
            this.captchaElement.reset()
        }
    }

    handleChange = recaptchaToken => {
        this.setState({ captcha: recaptchaToken })
    }

    render() {
        const { termsAccepted, validation, inProgress, showCaptcha } = this.state
        const { title, description, className, btnTitle, onCloseRequested } = this.props

        const policy = <div className={styles.policy}>
            <Checkbox
              green={true}
              checked={termsAccepted}
              onChange={this.onAgreementChange}>
                <span><Translate id={"form.disclaimer"} /></span>
            </Checkbox>
        </div>

        /*return <form className={`${styles.form} ${className}`}>
            <div className={styles.content}>
                <div className={styles.head}>
                    <div className={styles.title} dangerouslySetInnerHTML={{ __html: title }}></div>
                    <div className={styles.description}>{description}</div>
                </div>
                <CustomInput required type="text" placeholder="Ваше имя" />
                <CustomInput required type="text" placeholder="Ваш e-mail" />
                <CustomInput required type="text" placeholder="Ваш телефон" />
                <button className={styles.button}>{btnTitle}</button>
                {policy}
            </div>
        </form>
        */
        return <Translate>
            {
                (translate) => (
                    <>
                        <div className={`${styles.form} ${className}`}>
                            <div className={`${styles.content}`}>
                                <div className={styles.head}>
                                    <div className={styles.title} dangerouslySetInnerHTML={{ __html: title }}></div>
                                    <div className={styles.description}>{description}</div>
                                </div>
                                <CustomInput name={"name"}
                                             validation={validation}
                                             placeholder={translate("form.name")}
                                             value={this.state.name}
                                             onChange={this.handleFieldChange("name")}
                                             inputClassName={"gtm_send-input"}
                                             maxLength={50}/>
                                <CustomInput name={"email"}
                                             validation={validation}
                                             placeholder={translate("form.email")}
                                             value={this.state.email}
                                             onChange={this.handleFieldChange("email")}
                                             inputClassName={"gtm_send-input"}
                                             maxLength={50}/>
                                <CustomInput name={"phone"}
                                             validation={validation}
                                             placeholder={translate("form.phone")}
                                             value={this.state.phone}
                                             onChange={this.handleFieldChange("phone")}
                                             inputClassName={"gtm_send-input"}
                                             maxLength={255}/>
                                {
                                    showCaptcha ? <ReCaptcha ref={(el) => this.captchaElement = el}
                                                             elementID={this.generateKey()}
                                                             hl={translate("form.captchaLanguage")}
                                                             size="normal"
                                                             theme="light"
                                                             onloadCallback={this.onLoadRecaptcha}
                                                             verifyCallback={this.handleChange}
                                                             sitekey={useCaptchaToken()}/>
                                      : <div hidden={true}/>
                                }
                                {policy}
                                <button disabled={!termsAccepted}
                                        className={`${styles.send} ${styles.button} gtm_send-btn`}
                                        onClick={this.sendForm}>
                                    {inProgress ? <LoaderComponent/> : translate("form.register")}
                                </button>
                            </div>
                        </div>
                    </>)
            }
        </Translate>
    }
}

export default HeroCallbackFormRegistration