import React from "react"
import styles from "./hero-feature-page.module.scss"
import Icon from '../../../../../images/icons/white-under-arrow-icon.svg'
import Form from '../../../../forms/hero-form-registration'
import { openOverlay, openSuccessOverlay } from "../../../../../utils/overlay"

const Index = ({ title, subtitle, list, idUnderBlock, description,  offsetHeader, forClients, gift, advertisement }) => (
  <div className={styles.hero}>
    <div className={`container ${styles.container}`}>
      <div className={styles.wrap}>
        <div className={styles.info}>
          <h1 className={styles.title}>{title}</h1>
          <p className={styles.subtitle}>{subtitle}</p>
          <p className={styles.description}>{description}</p>
          <p className={styles.price}>от 802 руб./мес.</p>
          <ul className={styles.list}>
            {list.map((el, index) =>
              <li key={index} dangerouslySetInnerHTML={{ __html: el }}></li>
            )}
          </ul>
          
          <div className={styles.border}></div>
          <div className={styles.discount}>
            <p className={styles.forClients}>{forClients}</p>
            <div className={styles.iconswrap}>
              <img
                  alt={"Стрелка"} className={styles.right}
                  src={require("/src/images/registration/right.svg")}/>
              <img
                  alt={"Стрелка"} className={styles.down}
                  src={require("/src/images/registration/down.svg")}/>
            </div>
            <p className={styles.gift}>{gift}</p>
          </div>
          <div className={styles.advertisement}>
            <div className={styles.greed}>ЗАРЕГИСТРИРУЙТЕСЬ </div>
            <div className={styles.learn}>И ПРОЙДИТЕ БЕСПЛАТНОЕ ОБУЧЕНИЕ +</div>
            <div className={styles.freeTest}>10 ДНЕЙ ТЕСТА СИСТЕМЫ БЕЗ ОГРАНИЧЕНИЙ</div>
          </div>
          <p className={styles.conditions}>* Условия акции уточняйте у менеджеров Параплан CRM.</p>
        </div>
        <Form
          className={styles.heroForm}
          title="Бесплатный тест CRM <br /> 10 дней"
          description="Заполните эту форму и получите письмо с доступом в систему на свой email"
          btnTitle="Зарегистрироваться"
          onSuccess={()=> {openSuccessOverlay()}}
        />
      </div>
    </div>
  </div>
)

export default Index
