import React from "react"

import SEO from "../../components/seo"
import HeroFeaturePage from "../../components/pages/shared/hero/registration"
import Layout from "../../components/layout"


const MainPage = (props) => {
        return(
                <Layout forceFixedHeader={true} pageClassName={"about"} headerColor={"#FFFBFB"} page={"about"}>
                <SEO title="CRM-система для детских и образовательных центров" 
                description="Доступная CRM для детских центров и клубов. Автоматизация учета клиентов и продаж, календарь занятий, расчет зарплаты, интеграция с сайтом и многое другое. Демо-доступ 10 дней бесплатно."
                url={"https://paraplancrm.ru" + props.location.pathname}
                />
                <HeroFeaturePage
                title='CRM-система учета'
                subtitle="для детских и образовательных центров"
                idUnderBlock="#features"
                offsetHeader={80}
                list={[
                        '<b>Автоматизирует</b> процессы: от отчетности до приема оплат.',
                        'Увеличивает <b>доходы</b>.',
                        'Ускоряет <b>темп работы</b> команды.',
                        'Помогает <b>контролировать сотрудников</b>.',
                ]}
                forClients= 'Новым клиентам скидки'
                gift= 'до 25% + подарочные дни *'
                />                        
        </Layout>
        )
}

export default MainPage
